<template>
<div class="prizes-page">
  <div class="header">
    Club Prizes
  </div>
  <div class="prize-list">
    <div class="prize-each">
      1st: 12 months of verified status
    </div>
    <div class="prize-each">
      2nd: 4 months of verified status
    </div>
    <div class="prize-each">
      3rd-10th: 1 month of verified status
    </div>
  </div>
  <router-link to="/rankings" class="view-rankings">
    View club leaderboard
  </router-link>
  <div class="faq-header">
    FAQ
  </div>
  <div class="faq-panel">
    <div class="faq-question">
      What do I do to sign up?
    </div>
    <div class="faq-answer">
      Nothing! All UCLA clubs are automatically entered in the competition
    </div>
    <div class="faq-question">
      How are clubs ranked?
    </div>
    <div class="faq-answer">
      Clubs with more high-quality reviews are ranked higher
    </div>
    <div class="faq-question">
      How do I increase my club's ranking?
    </div>
    <div class="faq-answer">
      Ask people to leave an honest review on your club
    </div>
    <div class="faq-question">
      Can clubs cheat?
    </div>
    <div class="faq-answer">
      No. Our website can detect multiple reviews left by the same device.
    </div>
    <div class="faq-question">
      When is the winner decided?
    </div>
    <div class="faq-answer">
      December 1, 2021
    </div>
    <div class="faq-question">
      My question isn't answered. What do I do?
    </div>
    <div class="faq-answer">
      Contact us <router-link to="/Contact">here</router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<style scoped>
.prizes-page{
  padding: 40px 20px;
  max-width: 700px;
  margin: 0 auto;
}
.header{
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}
.prize-list{
  text-align: center;
  margin-bottom: 60px;
}
.prize-each{
  color: black;
  padding: 15px 15px;
  font-weight: 800;
  background: linear-gradient(90deg, rgba(239,239,239,0) 0%, rgb(125 173 251) 50%, rgba(255,255,255,0) 100%);
}
.faq-header{
  font-size: 24px;
  font-weight: 700;
  margin-top: 30px;
  text-align: left;
}
.view-rankings{
  text-decoration: none;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  color: var(--primary-color);
}
.view-rankings:hover{
  background: #D0D0D0;
}
.faq-panel{
  text-align: left;
  padding-left: 20px;
}
.faq-question{
  font-weight: 700;
  margin-top: 30px;
}
.faq-answer{
  font-size: 14px;
  color: gray;
  margin-top: 2px;
}
</style>